<template>
    <div class="card-tumb">
        <img :src="thumb" alt="" />
    </div>
</template>
  
<script>
export default {
    props: {
        thumb: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
};
</script>
  
<style lang="scss">
.card-tumb {
    img {
        height: 300px;
        width: 250px;
        border-radius: 8px;
        object-fit: cover;
    }
}

@media (max-width: 564px) {
    .card-tumb {
        img {
            width: 100%;
        }
    }
}
</style>