<template>
    <div>
        <!-- TEMPLATE EVENTOS -->
        <div class="cardTitle">
            <!-- HEADER -->
            <a-card class="title">
                <a-row :gutter="24" class="row">
                    <a-col>
                        <h6>Fórum Negocios - Continue no Foco | {{ producerName }}</h6>
                    </a-col>
                </a-row>
            </a-card>

            <!-- VIDEOS -->
            <div class="grid-row">
                <!-- skeletun -->
                <a-col v-if="isLoadindContents" :xs="24" :sm="12" :md="8" :lg="8" class="mb-24">
                    <template>
                        <div class="skeleton-loading"></div>
                        <div class="skeleton-loading"></div>
                        <div class="skeleton-loading"></div>
                    </template>
                </a-col>

                <a-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6" v-for="(content, index) in offers" :key="index">
                    <router-link :to="`/play/${content.id}`">
                        <CardProducerVideo :thumb="content.img"></CardProducerVideo>
                    </router-link>
                </a-col>
            </div>
        </div>
    </div>
</template>
<script>
import CardProducerVideo from "../../../components/Cards/CardProducerVideo.vue";

export default {
    components: { CardProducerVideo },
    data() {
        return {
            isLoadindContents: true,
            offers: [],
            producerName: null,
            producersToFilter: {
                "Giovani Begossi": [
                    "14e541b8-3234-4f78-98e8-77d55d0dc28f",
                    "df45a765-aee8-449c-b916-6df8796533ea",
                    "e3733673-7602-4bdd-8d9d-95510da0b35d",
                    "680f9d88-6233-4855-b3b3-6477513e3053",
                ],
                "Thiago Reis": [
                    "de282524-effc-4bfe-8858-9cca3fd5ad48",
                    "a05391a1-3c12-49a5-b7ad-7b1ede522faf",
                    "8d2d3c3e-787c-4a4c-bbc8-036265a8db27",
                    "733375e4-9eb3-4983-a95d-35d687d78cfc",
                    "34ae31dc-64d0-48c2-b397-fe1fd2c84970",
                ],
                "Natalia Beauty": [
                    "81fc56d2-6343-46ed-8835-0e095c92d82f",
                    "1e1a359a-32a1-4a5d-a62c-9b2a75627d6c",
                    "1fd7b46e-ae2c-42b9-87f9-db0fd79c91b2",
                    "4fa3b7a2-01f0-4ac0-818a-b51abf09c23a",
                ],
                "Alfredo Soares": [
                    "235c803c-8c08-49ab-8160-a47cf645408a",
                    "e1a15f95-8a55-43e6-971d-f27199cfa03e",
                    "0295cd2a-f241-4894-b210-d805448cf1d3",
                    "20203f31-2ec7-4864-a7f5-d345169b569c",
                ],
                "Thiago Eugenio": [""],
                "Joel Jota": [
                    "14e541b8-3234-4f78-98e8-77d55d0dc28f",
                    "83b267e2-064f-4cb3-8325-6e64c626e9a8",
                    "66b650ea-1166-433e-b0c4-096a9f5520f7",
                    "0a634df1-48da-4aff-af6f-49aa365a16dd",
                ],
            },
        };
    },
    computed: {
        filteredOffers() {
            return this.offers.filter((offer) =>
                offer.name.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        },
    },
    methods: {
        /* OBTER AS OFERTAS */
        getOffers() {
            this.$api.dataService
                .getData("member/purchasable")
                .then((res) => {
                    if (res.events || res.products || res.courses) {
                        this.offers = [
                            ...this.mapOffers(res.events, "Event"),
                            ...this.mapOffers(res.products, "Product"),
                            ...this.mapOffers(res.courses, "Course"),
                        ];

                        // Filtrar ofertas com base no producerName
                        this.offers = this.filterOffersByProducerName(this.offers);
                    }
                    this.isLoadindContents = false;
                })
                .catch((error) => {
                    console.error("Erro ao buscar ofertas:", error);
                });
        },

        mapOffers(offers, type) {
            if (!offers) return [];
            return offers.map((offer) => ({
                id: offer.id,
                name: offer.name || offer.title,
                description: offer.description,
                img: offer.banner ? offer.banner['1x1'] : "https://lp-js-libs.s3.sa-east-1.amazonaws.com/assets/Plataforma/sem-imagem.jpg",
                type: type,
            }));
        },

        filterOffersByProducerName(offers) {
            const producerIds = this.producersToFilter[this.producerName];

            if (!producerIds || producerIds.length === 0) {
                // Se o produtor não estiver na lista ou não tiver IDs associados, retornar todas as ofertas
                return offers;
            }

            return offers.filter((offer) => producerIds.includes(offer.id));
        }
    },
    mounted() {
        this.getOffers();
        this.producerName = this.$route.params.producer;
    },
};
</script>


    
  
<style lang="scss">
.cardTitle {
    width: 100%;
}

.title {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;

    margin-bottom: 20px;
}

@media (max-width: 600px) {
    .title {
        h6 {
            font-size: 14px;
        }
    }
}

.grid-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

.skeleton-loading {
    width: 100%;
    height: 300px;
    /* Ajuste a altura conforme necessário */
    background: linear-gradient(90deg, #0e1117 25%, #10161d 50%, #1a232e 75%);
    background-size: 200% 100%;
    animation: loading 3.5s infinite;
    border-radius: 10px;
    margin-bottom: 24px;
}
</style>
    
    